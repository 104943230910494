import { defineStore } from 'pinia'
import { ref, computed, reactive } from 'vue'
import { SiteService } from "@/service/SiteService";

export const useSiteStore = defineStore('site', () => {
    const settings = reactive({
        languages: null,
        displayLanguages: SiteService.getUserDisplayLanguage(),
        tagList: SiteService.getTagList(),
        videoList: null,
        isCalendar: false,
    });

    const userInfo = ref(null);

    const searchCategories = ref("01");
    
    const newsList = computed(() => {
        return SiteService.getNews();
    })

    const themeList = computed(() => {
        return SiteService.getThemeList(settings.displayLanguages)
    })

    const bannerList = computed(() => {
        return SiteService.getBannerList(settings.displayLanguages);
    })

    const topDestinations = computed(() => {
        return SiteService.getTopDestinations(settings.displayLanguages);
    })

    const popularData = computed(() => {
        return SiteService.getPopularDestination(settings.displayLanguages);
    })

    const mapData = computed(() => {
        return SiteService.getMap(settings.displayLanguages);
    })

    
    // get video list at intersection observer
    function getVideoList () {
        this.settings.videoList = SiteService.getVideo().slice(0, 18);
    }

    // update supported languages list
    function updateLanguages() {
        try {
            let langListData = SiteService.getSupportedLanguages();
            return this.settings.languages = langListData;
        } catch (error) {
            console.log(error);
        }
    }
    // update display languages and set cookie
    function updateDisplayLanguages(langCode) {
        SiteService.updateUserDisplayLanguage(langCode);
        this.settings.displayLanguages = langCode;
    }

    // 坐漁莊四國語言圖片
    const zagyosohList = computed(() => {
        return SiteService.getZagyosohList(settings.displayLanguages)
    })

    // 坐漁莊四國語言圖片
    const ikenotairaList = computed(() => {
        return SiteService.getIkenotairaList(settings.displayLanguages)
    })


    return { userInfo, settings, topDestinations, bannerList, themeList, popularData, searchCategories, mapData, updateLanguages, updateDisplayLanguages, getVideoList, newsList, zagyosohList, ikenotairaList }
},
{
    persist: {
        key: "StorageUserInfo",
        paths: ["userInfo", "settings.displayLanguages"]
    }
}
)