import { defineStore } from 'pinia'
import { ref } from 'vue'
import { AuthService } from "@/service/AuthService";
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2'

export const useAuthStore = defineStore('auth', () => {
  const accessToken = ref('');
  async function updateAccessToken () {
    try {
      let Token = await AuthService.getAccessToken();
      return this.accessToken = Token;
    } catch (error) {
      console.log(error);
    }
  };
  function loginGoogle() {
    try {
      AuthService.loginGoogle();
    } catch (error) {
      console.log(error);
    }
  }
  function loginFB() {
    try {
      AuthService.loginFB();
    } catch (error) {
      console.log(error);
    }
  }
  async function refreshToken(refreshToken){
    try {
      var token = await AuthService.refreshToken(refreshToken);
      return token;
    } catch (error) {
      console.log(error);
    }
  }
  function logOut() {
    AuthService.logOut();
  }

  // const { t } = useI18n();

  // function translate(text) {
  //   return t(text, {}, { locale: siteStore.settings.displayLanguages }
  //   );
  // }

  // error sweetalert
  // function errorSwal(title, text) {
  //   Swal.fire({
  //     title: translate(title) + "!",
  //     text: translate(text),
  //     icon: 'error',
  //     confirmButtonText: 'Confirm'
  //   })
  // }
  function errorSwal(title, text) {
    Swal.fire({
      title: title + "!",
      text: text,
      icon: 'error',
      confirmButtonText: 'Confirm'
    })
  }

  return { errorSwal, refreshToken, accessToken, updateAccessToken, loginGoogle, loginFB, logOut }
}) 