import axios from "axios";

export class AuthService {
  static apiKey = "";
  static apiSecret = "";

  static async getAccessToken() {
    return process.env.VUE_APP_ACCESSTOKEN;
  }

  static loginGoogle() {
    const googleAuthUrl = `${process.env.VUE_APP_API_URL}/api/Oauth/LoginGoogle`
    if (navigator.userAgent) {
      const customUserAgent = 'Mozilla/5.0 (Linux; Android 13; SM-G986U1 Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/111.0.5563.116 Mobile Safari/537.36 WEBVIEW TEST/1.3.1.106 (Phone; anonymous)';
      window.open(googleAuthUrl, '_self', `user-agent=${customUserAgent}`);
    } else {
      window.location.href = googleAuthUrl;
    }
  }

  static loginFB() {
    const FBAuthUrl = `https://www.facebook.com/v19.0/dialog/oauth?
client_id=${process.env.VUE_APP_FB_CLIENT_ID}
&scope=openid,public_profile,email
&display=popup
&response_type=code
&redirect_uri=${process.env.VUE_APP_FB_REDIRECT_URI}
&state=${process.env.VUE_APP_ACCESSTOKEN}`

    window.location.href = FBAuthUrl;
  }

  static logOut = () => {
    window.location.href = process.env.VUE_APP_API_URL + "/api/Oauth/Logout";
  };

  static refreshToken = async (refreshToken) => {
    const encodedRefreshToken = encodeURIComponent(refreshToken);
    const apiUrl = `${process.env.VUE_APP_API_URL}/api/Oauth/AccessToken?RefreshToken=${encodedRefreshToken}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };
}
