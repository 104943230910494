import axios from "axios";
import { AuthService } from "./AuthService";
import hotel from '@/assets/json/recommandHotel.json';

// TODO: 整個檔案都要改
export class HotelService {
  static getRecommandHotel(langCode) {
    // let accessToken = await AuthService.getAccessToken();
    // let dataURL =
    //   process.env.VUE_APP_API_URL +
    //   `/api/V1/P02/A05/A01/P02A05A01F001?L=${langCode}&D=` +
    //   window.location.hostname;
    // let response = await axios({
    //   method: "GET",
    //   url: dataURL,
    //   responseType: "json",
    //   headers: {
    //     Authorization: accessToken,
    //   },
    // }).catch(function (error) {
    //   console.log(error);
    // });

    //console.log(response);

    // return response.data.Data.P01A05A01F001List;
    if (langCode == "zh-TW") {
      return hotel.Data.hotelList_zhTW;
    } else if ( langCode == "zh-CN") {
    return hotel.Data.hotelList_zhCN;
    } else if ( langCode == "en-US") {
    return hotel.Data.hotelList_enUS;
    }
    else if ( langCode == "ja-JP") {
    return hotel.Data.hotelList_jaJP;
    }
  }
}
